.pageWrapper
  padding-top: 8px

.title
  font-weight: 700
  margin-top: 16px
  font-size: 24px

.filtersWrap
  margin-top: 16px

.carriageWrap
  margin-top: 16px
  display: flex
  gap: 16px
  width: 100%

.carriageSideBar
  flex-shrink: 0
  overflow: hidden

.carriageDetailWrap
  width: calc( 100% - 272px )
  flex: 1

.carriageDetail
  width: 100%
  box-shadow: 0px 0px 8px 0px rgba(61, 92, 221, 0.08)
  background: rgba(240, 243, 249, 1)
  border-radius: 4px

.tabs
  display: flex
  justify-content: center
  margin: 0 32px
  margin-top: 32px
  border-bottom: 1px solid rgba(226, 236, 244, 1)

.tabsContent
  padding: 32px
  margin-bottom: 32px
