// Normalize
@import "~normalize.css/normalize"

@import './initial/fonts'

html, body
    font-family: 'Raleway'

    *
        box-sizing: border-box
