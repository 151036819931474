@import '../../styles/mixins/grid'

.header
  height: 82px
  background: rgba(255, 255, 255, 1)

.headerContent
  @extend %container
  display: flex
  justify-content: space-between
  align-items: center
  height: 100%

.logo
  font-weight: 700
  font-size: 24px

.avatar
  width: 40px
  height: 40px
  border-radius: 50%
  background: rgba(176, 194, 217, 1)
  display: flex
  justify-content: center
  align-items: center
  color: white
  cursor: pointer

.headerMenu
  display: flex
  flex-direction: column
  padding-top: 16px

.headerMenuContent
  display: flex
  padding: 8px 24px
  padding-bottom: 16px
  justify-content: space-between

.headerMenuContentText
  display: flex
  flex-direction: column

.headerMenuContentName
  font-size: 16px
  font-weight: 700
  color: rgba(46, 64, 87, 1)
  line-height: 24px

.headerMenuContentSocial
  line-height: 24px
  font-size: 14px
  font-weight: 500

.headerMenuContentClose
  cursor: pointer

.headerMenuLogout
  display: flex
  padding: 12px 24px
  align-items: center
  border-top: 1px solid rgba(226, 236, 244, 1)
  cursor: pointer

.headerMenuLogoutIcon
  margin-right: 8px

.headerMenuLogoutText
  font-size: 16px
  font-weight: 600
  line-height: 24px
