.filters
  display: flex
  gap: 16px
  align-items: center

.filterItemDate
  width: 250px
  flex-shrink: 0

.filterItemSelect
  flex-shrink: 0
  width: 124px

.checkboxTooltip
  font-size: 14px
  font-weight: 400
  line-height: 20px
  text-align: center
  padding: 12px 16px
  color: rgba(255, 255, 255, 1)
  background: rgba(0, 0, 0, 0.8)
  border-radius: 2px
