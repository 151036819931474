.trainSliderWrap
  padding-right: 81px
  position: relative

  :global(.swiper-slide)
    width: auto

.swiperPrev, .swiperNext
  cursor: pointer
  width: 48px
  height: 48px
  border-radius: 50%
  box-shadow: 2px 0px 8px 0px rgba(46, 64, 87, 0.24)
  background: rgba(244, 247, 254, 1)
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  top: 50%
  transform: translateY(-50%)
  z-index: 1

.swiperPrev
  left: 16px

.swiperNext
  right: 17px

.swiperCountWrap
  position: relative
  height: 100%
  width: 100%

.swiperCount
  width: 20px
  height: 20px
  display: flex
  align-items: center
  justify-content: center
  font-weight: 600
  font-size: 14px
  color: rgba(244, 247, 254, 1)
  background: rgba(229, 89, 52, 1)
  border-radius: 50%
  position: absolute
  top: -5px
  right: -5px

.trainCarriage
  width: 104px
  min-height: 54px
  display: flex
  flex-direction: column

.trainCarriageClick
  cursor: pointer
  &:hover
    :global(.trainCarriagePosNumHover)
      color: rgba(46, 64, 87, 1)

.trainCarriageActive
  background: rgb(224, 228, 243)

.trainCarriagePosNum
  font-size: 18px
  line-height: 21px
  color: rgba(126, 144, 167, 1)
  font-weight: 700
  text-align: center
  height: 21px

.trainCarriageIcon
  margin-top: 10px
  position: relative

.trainCarriageNum
  position: absolute
  font-weight: 600
  font-size: 14px
  left: 0
  right: 0
  text-align: center
  top: 16px

.trainCarriageServices
  margin-top: 10px
  padding: 0 8px
  display: flex
  justify-content: space-between

//statuses
.trainCarriageDEFAULT
  :global(.icFill)
    fill: rgba(244, 247, 254, 1)

  :global(.icBorder)
    fill: rgba(226, 236, 244, 1)

.trainCarriageTextDEFAULT
  color: rgba(126, 144, 167, 1)

.trainCarriageServiceDEFAULT
  *
    fill: rgba(46, 64, 87, 0.7)

.trainCarriageSUCCESS
  :global(.icFill)
    fill: #E5FFF4

  :global(.icBorder)
    fill: #28C281

.trainCarriageTextSUCCESS
  color: rgba(0, 154, 89, 1)

.trainCarriageServiceSUCCESS
  *
    fill: rgba(40, 194, 129, 1)

.trainCarriageERROR
  :global(.icFill)
    fill: rgba(255, 231, 232, 1)

  :global(.icBorder)
    fill: rgba(252, 76, 83, 1)

.trainCarriageTextERROR
  color: rgba(212, 36, 43, 1)

.trainCarriageServiceERROR
  *
    fill: rgba(252, 76, 83, 1)
