.carriageItem
  display: flex
  flex-direction: column
  padding: 0 32px

.carriageItemTitle
  font-size: 20px
  font-weight: 600
  text-align: center
  margin-top: 24px
  margin-bottom: 16px
  padding: 12px
  display: flex
  justify-content: center

.carriageItemIcon
  display: flex
  align-items: center
  margin: 0 20px
  cursor: pointer

.carriageItemIconDisabled
  opacity: 0.2
