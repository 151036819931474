.drawerContent
  width: 544px

.drawerHeader
  padding: 16px 32px
  background: rgba(255, 255, 255, 1)
  position: sticky
  top: 0
  z-index: 1

.drawerHeaderTitle
  display: flex
  justify-content: space-between
  margin-bottom: 13px

.drawerHeaderClose
  cursor: pointer

.drawerHeaderNum
  font-weight: 700
  font-size: 20px
  color: rgba(46, 64, 87, 1)

.drawerHeaderSteps
  display: flex
  align-items: center
  font-weight: 700
  font-size: 18px
  color: rgba(46, 64, 87, 1)
  min-width: 24px

  svg
    margin-right: 8px

.drawerHeaderStepsError
  color: rgba(252, 76, 83, 1)

.drawerHeaderStepsDefault
  color: rgba(46, 64, 87, 1)

.drawerHeaderStepsSuccess
  color: rgba(40, 194, 129, 1)

.drawerHeaderCaption
  font-weight: 500
  font-size: 16px
  color: rgba(126, 144, 167, 1)
  text-align: center

.drawerInfo
  margin: 24px
  background: rgba(255, 255, 255, 1)
  border-radius: 4px

.drawerInfoItem
  display: grid
  grid-template-columns: 152px 1fr

  :global(.drawerTitle)
    font-size: 16px

  :global(.drawerCaption)
    font-size: 12px

  :global(.drawerInfoText)
    &::before
      content: ""
      position: absolute
      height: 3px
      width: 3px
      border: 1.5px solid #7e90a7
      border-radius: 50%
      left: 9px

    &::after
      content: ""
      position: absolute
      left: 11px
      top: 0
      border-left: 2px dotted #7e90a7
      bottom: 0

  &:first-child, &:last-child
    :global(.drawerTitle)
      font-size: 20px

    :global(.drawerCaption)
      font-size: 16px

    :global(.drawerInfoText)
      &::before
        content: ''
        position: absolute
        background-color: white
        height: 24px
        width: 24px
        left: 0
        border: none
        z-index: 1
        background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.84279 4.56825C9.2432 1.16784 14.7564 1.16784 18.1568 4.56825C21.5572 7.96866 21.5572 13.4818 18.1568 16.8822L16.9698 18.0561C16.0949 18.9148 14.9598 20.0187 13.5639 21.3683C12.6917 22.2117 11.3079 22.2115 10.4358 21.3681L6.94466 17.9722C6.5059 17.5414 6.13863 17.1781 5.84279 16.8822C2.44238 13.4818 2.44238 7.96866 5.84279 4.56825ZM17.0961 5.62891C14.2815 2.81429 9.71807 2.81429 6.90345 5.62891C4.08882 8.44354 4.08882 13.0069 6.90345 15.8216L8.39052 17.289C9.20943 18.0905 10.2389 19.0909 11.4786 20.2899C11.7693 20.571 12.2305 20.571 12.5213 20.2899L15.9162 16.9885C16.3851 16.5282 16.7785 16.1392 17.0961 15.8216C19.9107 13.0069 19.9107 8.44354 17.0961 5.62891ZM11.9998 7.99891C13.6575 7.99891 15.0013 9.34275 15.0013 11.0005C15.0013 12.6582 13.6575 14.002 11.9998 14.002C10.3421 14.002 8.99823 12.6582 8.99823 11.0005C8.99823 9.34275 10.3421 7.99891 11.9998 7.99891ZM11.9998 9.49891C11.1705 9.49891 10.4982 10.1712 10.4982 11.0005C10.4982 11.8297 11.1705 12.502 11.9998 12.502C12.8291 12.502 13.5013 11.8297 13.5013 11.0005C13.5013 10.1712 12.8291 9.49891 11.9998 9.49891Z" fill="%237E90A7"/></svg>')

  &:first-child
    :global(.drawerInfoText)
      &::after
        top: 50%

  &:last-child
    :global(.drawerInfoText)
      &::after
        top: 0
        bottom: 50%

.drawerInfoTimeWrap
  display: flex
  flex-direction: column
  padding: 24px
  flex-shrink: 0

.drawerInfoTitle
  font-weight: 700
  color: rgba(46, 64, 87, 1)
  margin-bottom: 4px

.drawerInfoCaption
  font-weight: 500
  color: rgba(126, 144, 167, 1)

.drawerInfoTextWrap
  position: relative
  padding: 24px
  display: flex
  flex-direction: column
  flex: 1
  padding-left: 44px
  overflow: hidden
