.chartWrapper
  background: rgba(255, 255, 255, 1)
  box-shadow: 0px 0px 8px 0px rgba(61, 92, 221, 0.08)
  border-radius: 4px

  &:not(:last-child)
    margin-bottom: 24px

.showMore
  display: flex
  justify-content: center
