.trainWrap
  margin: 0 32px
  padding-bottom: 32px
  border-bottom: 1px solid rgba(226, 236, 244, 1)
  overflow: hidden

.trainHeader
  padding: 32px 0px
  display: flex
  font-size: 20px

.trainHeaderNum
  font-weight: 700
  color: rgba(46, 64, 87, 1)

.trainHeaderTitle
  font-weight: 500
  color: rgba(61, 92, 221, 1)
  text-decoration: underline
  margin-left: 8px
  cursor: pointer

.trainContent
  display: block
