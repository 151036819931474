.trackSliderWrap
  display: flex
  flex-direction: column

.trackSliderHeader
  display: flex
  justify-content: space-between
  width: 100%

.trackSliderHeaderItem
  flex: 1
  flex-wrap: nowrap
  align-items: center

.trackSliderHeaderItemTabs
  display: flex

.trackSliderHeaderItemTabTitle
  font-size: 18px
  font-weight: 700
  color: rgba(46, 64, 87, 1)

.trackSliderHeaderItemTabSubTitle
  color: rgba(46, 64, 87, 1)
  margin-left: 24px
  font-size: 16px
  font-weight: 500
  display: flex

.trackSliderHeaderItemRoute
  display: flex
  flex-direction: column
  text-align: center

.trackSliderHeaderItemRouteTitle
  font-size: 18px
  font-weight: 500
  color: rgba(46, 64, 87, 1)

.trackSliderHeaderItemRouteSubTitle
  font-size: 14px
  font-weight: 500
  color: rgba(106, 124, 147, 1)

.trackSlider
  position: relative
  display: flex
  margin-top: 12px
  padding-right: 60px

  :global(.swiper-slide)
    width: auto

    &:first-child
      :global(.trackSliderItemBorder)
        border-radius: 4px 0px 0px 4px

    &:last-child
      :global(.trackSliderItemBorder)
        border-radius: 0px 4px 4px 0px

  :global(.swiper)
    width: 100%

.trackSliderItemWrap
  display: flex
  flex-direction: column
  align-items: end
  padding-bottom: 10px

.trackSliderItemClose
  display: flex
  align-items: center
  cursor: pointer
  position: absolute
  right: 10px
  z-index: 2

.trackSliderItem
  display: flex
  align-items: center
  border: 0.5px solid rgba(176, 194, 217, 1)

.trackSliderItemText
  padding: 8px 16px
  padding-right: 32px
  cursor: pointer
  white-space: nowrap
  color: rgba(46, 64, 87, 1)
  font-size: 14px
  font-weight: 500

.trackSliderItemDisabled
  border: 0.5px dashed rgba(176, 194, 217, 1)
  cursor: default

.trackSliderItemActive
  border: 1px solid rgba(61, 92, 221, 1)
  color: rgba(61, 92, 221, 1)
  background: rgba(215, 228, 255, 1)

.trackSliderItemCaption
  font-size: 10px
  font-weight: 500
  color: rgba(106, 124, 147, 1)
  width: 40px
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap
  transform: rotate(-45deg)
  margin-top: 10px

.tabsWrapper
  margin-left: 10px
  column-gap: 6px

.tabWrapper
  padding-bottom: 0px
  cursor: pointer

.swiperPrev, .swiperNext
  cursor: pointer
  width: 48px
  height: 48px
  border-radius: 50%
  box-shadow: 2px 0px 8px 0px rgba(46, 64, 87, 0.24)
  background: rgba(244, 247, 254, 1)
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  top: 50%
  transform: translateY(-50%)
  z-index: 1

.swiperPrev
  left: 0

.swiperNext
  right: 0

.tooltipWrap
  display: flex
  flex-direction: column

.tooltipHeader
  font-family: 'Raleway'
  font-weight: 500
  text-align: center
  margin-top: 12px
  margin-bottom: 8px

.tooltipTitle
  color: rgba(46, 64, 87, 1)
  font-size: 14px

.tooltipSubTitle
  color: rgba(106, 124, 147, 1)
  font-size: 12px

.tooltipContent
  :global(.MuiTable-root)
    min-width: auto

  :global(.MuiTableCell-root)
    padding: 5px
