.chartWrap
  display: flex
  flex-direction: column
  overflow: hidden

.chartHeader
  display: flex
  padding: 24px
  justify-content: space-between

.chartTitle
  font-weight: 700
  color: rgba(76, 94, 117, 1)

.chartGroupButtons
  display: flex
  align-items: center
  gap: 32px

.chartDownload
  cursor: pointer
  font-size: 14px
  font-weight: 600
  color: rgba(61, 92, 221, 1)
  display: flex
  align-items: center
  gap: 10px

.chartDownloadDisabled
  cursor: default
  pointer-events: none
  opacity: 0.6

.chartHideTreeshold
  font-size: 14px
  font-weight: 600
  color: rgba(61, 92, 221, 1)
  cursor: pointer

.chart
  display: flex
  height: 311px

.echart
  position: relative
  padding-bottom: 16px
  margin: 0 -40px
  margin-top: -30px
  margin-bottom: 0
