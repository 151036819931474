.wrap
    display: grid
    grid-gap: 4px
    width: fit-content

.underline
    position: relative

    &::after
        content: ""
        width: 100%
        position: absolute
        bottom: 0
        left: 0px
        height: 2px
        background-color: transparent
        display: block

.button
    font-weight: 500
    font-size: 16px
    padding-bottom: 16px
    position: relative
    white-space: nowrap
    transition: color .2s
    grid-row: 1
    color: rgba(46, 64, 87, 1)
    cursor: pointer

.small
    padding-bottom: 8px

.buttonActive
    color: rgba(61, 92, 221, 1)

    &::after
        background-color: rgba(61, 92, 221, 1)

.withIcon
    display: grid
    grid-auto-flow: column
    justify-content: start
    column-gap: 8px
    align-items: center

.iconRight
    grid-column: 2

.externalLabelBottom
    grid-row: 2

.externalLabelTop
    grid-row: -2
