.tabTable
  margin-top: 24px

.tabChart
  display: flex
  flex-direction: column

.tabTrackSlider
  position: sticky
  top: -1px
  background: rgb(240, 243, 249)
  padding: 0 32px
  padding-bottom: 24px
  padding-top: 24px
  z-index: 1
  margin: 0 -32px

.tabTrackSliderShadow
  box-shadow: 0px 4px 16px 0px rgba(46, 64, 87, 0.24)

.tabSubTabs
  display: flex
  justify-content: center
  gap: 8px
  flex-wrap: wrap

.tabSubTabItem
  display: flex
  align-items: center
  justify-content: center
  border: 1px solid rgba(176, 194, 217, 1)
  background: transparent
  height: 35px
  font-weight: 500
  font-size: 14px
  color: rgba(106, 124, 147, 1)
  box-shadow: 0px 0px 8px 0px rgba(61, 92, 221, 0.08)
  border-radius: 4px
  padding: 8px 16px
  cursor: pointer

.tabSubTabItemActive
  background: rgba(46, 64, 87, 1)
  color: rgba(255, 255, 255, 1)
