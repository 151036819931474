.wrap
    display: grid
    grid-auto-flow: column
    column-gap: 32px
    overflow-x: auto
    -ms-overflow-style: none
    scrollbar-width: none
    &::-webkit-scrollbar
        display: none

.center
    justify-content: center
