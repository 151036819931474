.wrap
  position: relative

.list
  margin: 0
  padding: 0
  list-style: none
  display: flex
  position: relative
  scrollbar-width: none
  -ms-overflow-style: none
  align-items: center

  &::-webkit-scrollbar
    display: none

.listItem
  display: inline-flex
  padding: 10px 0

.item
  position: relative
  color: rgba(106, 124, 147, 1)
  text-decoration: none

  &:first-child
    padding-left: 0

.current
  color: rgba(61, 92, 221, 1)

.arrow
  width: 20px
  height: 20px
  margin: 0 4px

.wrapScroll
  &::after
    width: 100%

.disabled
  //todo
