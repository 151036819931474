.select
  min-width: 100px
  width: 100%
  background: white
  border-radius: 4px

  :global(.Mui-disabled)
    pointer-events: none
    background-color: rgba(226, 236, 244, 1)

  :global(.MuiSelect-icon)
    top: auto

  :global(.MuiOutlinedInput-notchedOutline)
    border: none

.placeholder
  color: rgba(106, 124, 147, 1)
  font-size: 14px
  font-weight: 500

.menuFooter
  position: sticky
  bottom: 0
  display: flex
  box-shadow: 0px 4px 16px 0px rgba(46, 64, 87, 0.24)
  background: rgba(255, 255, 255, 1)
  padding: 16px
  gap: 10px

.search
  input::placeholder
    font-family: 'Raleway'
    font-size: 14px

  :global(.MuiOutlinedInput-notchedOutline)
    border: none
