.trainWrap
  display: flex
  flex-direction: column
  box-shadow: 0px 0px 8px 0px rgba(61, 92, 221, 0.08)
  border-radius: 4px
  background: rgba(255, 255, 255, 1)
  overflow: hidden

.trainHeader
  padding: 16px
  display: flex
  justify-content: space-between
  align-items: center
  border-bottom: 1px solid rgba(226, 236, 244, 1)

.trainNumWrap, .trainArrivalAndDebarture, .trainServices
  display: flex
  font-size: 18px
  line-height: 21px

.trainNum
  font-weight: 700
  margin-right: 5px
  color: rgba(46, 64, 87, 1)

.trainTitle
  font-weight: 500
  color: rgba(46, 64, 87, 1)

.trainArrivalAndDebartureCaption, .trainServicesCaption
  font-weight: 500
  color: rgba(126, 144, 167, 1)
  margin-right: 5px

.trainArrivalAndDebartureTitle, .trainServicesNum
  font-weight: 700
  color: rgba(46, 64, 87, 1)

.trainContent
  padding-top: 32px
  padding-bottom: 32px
  padding-left: 16px

.trainServicesNumError
  color: rgba(252, 76, 83, 1)

.trainServicesNumDefault
  color: rgba(46, 64, 87, 1)

.trainServicesNumSuccess
  color: rgba(40, 194, 129, 1)
