.pageWrapper
  padding-top: 8px
  max-width: 1072px
  margin: 0 auto

.title
  font-weight: 700
  margin-top: 16px
  font-size: 24px

.filtersWrap
  margin-top: 16px

.tableWrap
  margin-top: 16px
  margin-bottom: 16px

.showMore
  display: flex
  justify-content: center
  margin: 16px 0

.loaderWrap
  display: flex
  justify-content: center
  align-items: center
  height: 500px
