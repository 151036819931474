.pageWrapper
  padding-top: 8px

.title
  font-weight: 700
  margin-top: 16px
  font-size: 24px

.filtersWrap
  margin-top: 16px

.trainsWrap
  margin-top: 16px
  display: flex
  flex-direction: column
  gap: 16px

.showMore
  display: flex
  justify-content: center
  margin: 16px 0
