.carriage
  padding: 0 40px
  border: 1px solid rgba(176, 194, 217, 1)
  border-radius: 32px
  display: flex
  justify-content: space-between
  min-height: 320px

.carriageDoorWrap
  flex-shrink: 0
  display: flex
  align-items: flex-end

.carriageDoor
  border-top: 1px solid rgba(176, 194, 217, 1)
  border-left: 1px solid rgba(176, 194, 217, 1)
  border-right: 1px solid rgba(176, 194, 217, 1)
  width: 104px
  height: 180px
  border-top-left-radius: 32px
  border-top-right-radius: 32px

.carriageDoorMirror
  width: 40px
  height: 80px
  border-radius: 12px
  border: 1px solid rgba(176, 194, 217, 1)
  margin: 32px
  margin-bottom: 68px

.carriageServicesWrap
  position: relative
  flex: 1
  margin: 16px 30px
  background: rgba(255, 255, 255, 1)
  padding: 16px
  gap: 16px
  display: flex
  overflow: hidden
  :global(.swiper-slide)
    width: auto

  :global(.swiper)
    width: 100%

.carriageServiceItemSlide
  display: flex
  flex-direction: column

.carriageServiceItem
  cursor: pointer
  padding: 16px
  width: 148px
  height: 130px
  border-radius: 4px
  flex-shrink: 0
  display: flex
  flex-direction: column
  justify-content: space-between

  &:not(:last-child)
    margin-bottom: 16px

.swiperPrev, .swiperNext
  cursor: pointer
  width: 48px
  height: 48px
  border-radius: 50%
  box-shadow: 2px 0px 8px 0px rgba(46, 64, 87, 0.24)
  background: rgba(244, 247, 254, 1)
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  top: 50%
  transform: translateY(-50%)
  z-index: 1

.swiperPrev
  left: 5px

.swiperNext
  right: 5px

.carriageServiceItemDEFAULT
  background: rgba(225, 231, 243, 1)

.carriageServiceItemSUCCESS
  background: rgba(229, 255, 244, 1)

.carriageServiceItemERROR
  background: rgba(255, 231, 232, 1)

.carriageServiceItemHeader
  position: relative

.carriageServiceItemHeaderTitle
  padding-right: 40px
  font-size: 16px
  font-weight: 700
  color: rgba(46, 64, 87, 1)
  margin-bottom: 4px
  word-wrap: break-word

.carriageServiceItemHeaderSubTitle
  font-size: 16px
  font-weight: 500
  color: rgba(46, 64, 87, 1)

.carriageServiceItemHeaderIcon
  position: absolute
  right: -5px
  top: -5px

.carriageServiceItemContent
  position: relative

.carriageServiceItemContentText
  font-size: 12px
  font-weight: 500
  color: rgba(126, 144, 167, 1)
  margin-bottom: 2px

.carriageServiceItemContentCaption
  font-size: 14px
  font-weight: 500
  color: rgba(46, 64, 87, 1)

.noData
  color: rgba(126, 144, 167, 1)
  font-size: 16px
  font-weight: 500
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
