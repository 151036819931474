@font-face
    font-family: 'Raleway'
    src: url('../../assets/fonts/Raleway-Medium.woff2') format("woff2"), url('../../assets/fonts/Raleway-Medium.woff') format("woff")
    font-weight: 500
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Raleway'
    src: url('../../assets/fonts/Raleway-Regular.woff2') format("woff2"), url('../../assets/fonts/Raleway-Regular.woff') format("woff")
    font-weight: 400
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Raleway'
    src: url('../../assets/fonts/Raleway-Bold.woff2') format("woff2"), url('../../assets/fonts/Raleway-Bold.woff') format("woff")
    font-weight: 700
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Raleway'
    src: url('../../assets/fonts/Raleway-SemiBold.woff2') format("woff2"), url('../../assets/fonts/Raleway-SemiBold.woff') format("woff")
    font-weight: 600
    font-style: normal
    font-display: swap
