.tableHeader
    background: rgba(255, 255, 255, 1)
    padding: 16px
    display: flex
    justify-content: space-between
    align-items: center

.title
    font-weight: 700
    font-size: 18px
    color: rgba(46, 64, 87, 1)

.search
    width: 368px

    input::placeholder
        font-family: 'Raleway'
        font-size: 14px

    :global(.MuiOutlinedInput-notchedOutline)
        border: none
