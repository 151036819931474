.input
    width: 100%
    input::placeholder
        font-family: 'Raleway'
        font-size: 16px

    :global(.MuiInputBase-root)
        border-radius: 0
        font-family: 'Raleway'

.inputLabel
    font-weight: 500
    font-size: 14px
    line-height: 20px
    margin-bottom: 4px
