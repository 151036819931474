.chartWrapper
  background: rgba(255, 255, 255, 1)
  box-shadow: 0px 0px 8px 0px rgba(61, 92, 221, 0.08)
  border-radius: 4px

  &:first-child
    margin-top: 24px

  &:not(:last-child)
    margin-bottom: 24px

.showMore
  display: flex
  justify-content: center

.tableWrap
  display: flex
  flex-direction: column

.tableTitle
  font-weight: 700
  margin-bottom: 16px
  font-size: 20px

.paginationWrap
  display: flex
  justify-content: center
  margin-top: 16px

.severityItem
  padding: 4px 8px 4px 8px
  border-radius: 2px
  font-size: 10px
  font-weight: 600
  display: inline-block
  text-transform: uppercase

.severityItemHigh
  background: rgba(252, 76, 83, 1)
  color: #fff
