.sideBarItem
  width: 100%
  border-radius: 4px
  background: rgba(240, 243, 249, 1)
  box-shadow: 0px 0px 8px 0px rgba(61, 92, 221, 0.08)
  padding: 20px 16px
  cursor: pointer

.sideBarItemActive
  background: rgba(255, 255, 255, 1)

.sideBarItemHeader
  display: flex
  flex-direction: column

.sideBarItemHeaderTitle
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 4px

.sideBarItemHeaderTitleNum
  font-weight: 700
  font-size: 16px
  color: rgba(46, 64, 87, 1)

.sideBarItemHeaderTitleIcon
  display: flex
  align-items: center
  font-weight: 700
  font-size: 18px

  svg
    margin-right: 8px

.sideBarItemHeaderTitleIconError
  color: rgba(252, 76, 83, 1)

.sideBarItemHeaderTitleIconDefault
  color: rgba(46, 64, 87, 1)

.sideBarItemHeaderTitleIconSuccess
  color: rgba(40, 194, 129, 1)

.sideBarItemHeaderCaption
  font-weight: 500
  font-size: 16px

.sideBarItemContent
  margin-top: 23px
  display: flex
  justify-content: space-between
  gap: 16px

.sideBarItemContentItem
  display: flex
  flex-direction: column

.sideBarItemContentItemCaption
  font-weight: 500
  font-size: 12px
  color: rgba(126, 144, 167, 1)
  margin-bottom: 2px

.sideBarItemContentItemTitle
  font-weight: 500
  font-size: 14px
  color: rgba(46, 64, 87, 1)
