.sideBarSlider
  width: 256px
  height: 816px
  position: relative
  padding-bottom: 56px

.sideBarSliderWrap
  height: 100%
  width: 100%
  overflow: hidden

.swiperPrev, .swiperNext
  cursor: pointer
  width: 48px
  height: 48px
  border-radius: 50%
  box-shadow: 2px 0px 8px 0px rgba(46, 64, 87, 0.24)
  background: rgba(244, 247, 254, 1)
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  left: 50%
  transform: translateX(-50%)
  z-index: 1

.swiperNext
  bottom: 1px

.swiperPrev
  top: 1px
  transform: rotate(180deg)

.showMore
  display: flex
  justify-content: center
  margin: 16px 0
