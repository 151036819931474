@import '../../styles/mixins/grid'

.container
  width: 100%
  min-height: 100vh
  background: rgba(46, 64, 87, 0.2)
  display: grid
  grid-template-rows: max-content 1fr

.content
  @extend %container
