.authForm
  margin: auto
  padding: 32px 67px
  background: rgba(255, 255, 255, 1)
  box-shadow: 0px 0px 8px 0px rgba(61, 92, 221, 0.08)

.title
  text-align: center
  font-weight: 700
  font-size: 32px
  color: rgba(46, 64, 87, 1)
  margin-bottom: 48px

.alert
  margin-bottom: 24px

  :global(.MuiAlert-icon), :global(.MuiAlert-message)
    padding: 0
    line-height: 24px

.form
  width: 394px

.formItem
  margin-bottom: 8px

.formHelpers
  display: flex
  justify-content: flex-end
  align-items: center

.formForgotPass
  padding: 8px 0

  a
    text-decoration: none
    color: rgba(61, 92, 221, 1)
    font-weight: 500

.formRememberPass
  padding: 8px 0

.formButtonWrap
  margin-top: 48px
