.wrap
  user-select: none
  width: 100%

.select
  background-color: #FFFFFF
  font-weight: 500
  font-size: 14px
  cursor: pointer
  border-radius: 4px
  padding: 8px 16px
  display: flex
  align-items: center
  gap: 8px
  justify-content: space-between

  &:hover
    box-shadow: inset 0 0 0 2px rgba(217, 217, 217, 1)

.opened
  box-shadow: inset 0 0 0 2px rgba(61, 92, 221, 1)
  svg
    path
      fill: #000000

.disabled
  pointer-events: none
  background-color: rgba(226, 236, 244, 1)

  svg
    path
      fill: rgba(217, 217, 217, 1)

  .value
    color: rgba(166, 166, 166, 1)

.value

.icon
  display: flex
  justify-content: center
  align-items: center

.calendarWrap
  position: absolute
  width: 280px
  height: 290px
  background: #FFFFFF
  box-shadow: 0px 4px 30px 0px #94949440
  z-index: 101
  border-radius: 10px
  top: calc(100% + 8px)
  right: 0
  padding: 16px
  left: 0

.fullWidth
  width: 100%

.monthController
  display: flex
  align-items: center
  justify-content: space-between

.month

.disabledMonth
  pointer-events: none
  svg
    path
      stroke: #A6A6A6

.week
  display: grid
  grid-template-columns: repeat(7, 1fr)
  margin-top: 4px
  font-weight: 400
  text-transform: uppercase
  font-size: 14px

.weekDay
  height: 20px
  display: flex
  justify-content: center
  align-items: center
  color: #747474
  padding-top: 16px

.days
  display: grid
  grid-template-columns: repeat(7, 1fr)
  grid-template-rows: repeat(6, 1fr)
  row-gap: 4px
  margin-top: 4px

.day
  position: relative
  height: 28px
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer

.disableDay
  color: #A6A6A6 !important
  pointer-events: none !important

.today
  &::after
    content: ""
    display: block
    width: 6px
    height: 6px
    position: absolute
    bottom: -1px
    left: 50%
    z-index: 1
    background-color: rgba(61, 92, 221, 1)
    border-radius: 50%
    transform: translateX(-50%)

.isOneDaySelected
  background-color: rgba(61, 92, 221, 1) !important
  border-radius: 8px

.rangeStart
  background-color: rgba(61, 92, 221, 1)!important
  border-radius: 8px 0 0 8px

.rangeEnd
  background-color: rgba(61, 92, 221, 1)!important
  border-radius: 0 8px 8px 0

.inRange
  background-color: rgba(243, 246, 249, 1) !important
